#root {
  height: 100%;
  width: 100%;
}

img {
  pointer-events: none;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* Disable scrollbar IE 10+ */
  overflow-y: visible;
}

html {
  height: 100%;
  box-sizing: border-box;
  -ms-overflow-style: none;  /* Hide scrollbar on IE and Edge */
  user-select: none;
}

*, *:before, *:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  scrollbar-width: none; /* Firefox implementation */
}


/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}

@font-face {
  font-family: 'Zilla Slab';
  src: local('Zilla Slab'), url(./fonts/ZillaSlab/ZillaSlab-Regular.ttf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Zilla Slab';
  src: local('Zilla Slab'), url(./fonts/ZillaSlab/ZillaSlab-SemiBoldItalic.ttf) format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Zilla Slab';
  src: local('Zilla Slab'), url(./fonts/ZillaSlab/ZillaSlab-MediumItalic.ttf) format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Robot'), url(./fonts/Roboto/Roboto-Regular.ttf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Robot'), url(./fonts/Roboto/Roboto-Medium.ttf) format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Robot'), url(./fonts/Roboto/Roboto-MediumItalic.ttf) format('opentype');
  font-weight: 500;
  font-style: italic;
}